<template>
  <page-container title="一键生成报告" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 24px; padding-bottom: 24px;">
      <div class="box-container-inner">
        <a-form-model ref="modalForm" :model="formDatas" :rules="formRules" :label-col="{span:7}" :wrapper-col="{span:14}">
          <a-form-model-item :wrapper-col="{ span: 22, offset: 0 }" style="width: 100%; margin-bottom: 20px; overflow: hidden;">
            <a-button size="large" type="primary" @click="exportData" style="float: right;">生成报告</a-button>
          </a-form-model-item>
          <a-form-model-item label="区域" prop="areacode">
            <!-- <a-select v-model="formDatas.areacode">
              <a-select-option v-for="(item, index) in areaOptions" :key="index" :value="item.areacode">{{item.areaname}}</a-select-option>
            </a-select> -->
            <a-cascader :show-search="{ areaFilter }" :field-names="{ label: 'areaname', value: 'areacode', children: 'children' }" :options="areaOptions" v-model="areaCascaderSelected" :load-data="areaOnLoadChildren" change-on-select placeholder="请选择区域" :allow-clear="false"></a-cascader>
          </a-form-model-item>
          <a-form-model-item label="单位" prop="userdepid">
            <a-cascader :show-search="{ filter }" :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="deptOptions" change-on-select v-model="deptCascaderSelected" :load-data="deptOnLoadChildren" placeholder="请选择单位" :allow-clear="false"></a-cascader>
          </a-form-model-item>
          <a-form-model-item label="年份" prop="year">
            <a-select v-model="formDatas.year">
              <a-select-option v-for="(item, index) in yearOptions" :key="index" :value="item">{{item+'年'}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="半年" prop="halfyear">
            <a-select v-model="formDatas.halfyear">
              <a-select-option value="1">上半年</a-select-option>
              <a-select-option value="2">下半年</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="季度" prop="quarter">
            <a-select v-model="formDatas.quarter">
              <template v-if="formDatas.halfyear == '1'">
                <a-select-option v-for="i in 2" :key="i" :value="i+''">{{'第'+i+'季度'}}</a-select-option>
              </template>
              <template v-else-if="formDatas.halfyear == '2'">
                <a-select-option v-for="i in 2" :key="i" :value="i+2+''">{{'第'+(i+2)+'季度'}}</a-select-option>
              </template>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="月份" prop="month">
            <a-select v-model="formDatas.month">
              <template v-if="formDatas.quarter=='1'">
                <a-select-option v-for="i in 3" :key="i" :value="i+''">{{i+'月'}}</a-select-option>
              </template>
              <template v-else-if="formDatas.quarter=='2'">
                <a-select-option v-for="i in 3" :key="i" :value="i+3+''">{{i+3+'月'}}</a-select-option>
              </template>
              <template v-else-if="formDatas.quarter=='3'">
                <a-select-option v-for="i in 3" :key="i" :value="i+6+''">{{i+6+'月'}}</a-select-option>
              </template>
              <template v-else-if="formDatas.quarter=='4'">
                <a-select-option v-for="i in 3" :key="i" :value="i+9+''">{{i+9+'月'}}</a-select-option>
              </template>
            </a-select>
          </a-form-model-item>
        </a-form-model>
      </div>
    </div>
  </page-container>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import deptselect from '@/mixins/deptselect'
import { getItemFromArrayByKey } from 'U'
import { getAreaByLift, exporIotWordStatistic, exporMaintenanceWordStatistic } from 'A/bigdata.js'
import moment from 'moment'
export default {
  mixins: [deptselect],
  props: {
    pageType: {
      default: 'ai'
    }
  },
  data() {
    return {
      breadcrumb: [
        {
          name: '大数据辅助决策',
          path: ''
        },
        {
          name: this.pageType=='maintenance'?'维保统计分析':'AI+物联网统计分析',
          path: ''
        },
        {
          name: '一键生成报告',
          path: ''
        },
      ],
      formDatas: {
        areacode: '',
        userdepid: '',
        year: '',
        halfyear: '',
        quarter: '',
        month: '',
      },
      areaOptions: [],
      areaCascaderSelected: [],
      yearOptions: [],
      formRules: {
        areacode: [{required: true, message: '请选择区域'}],
        userdepid: [{required: true, message: '请选择单位'}],
        year: [{required: true, message: '请选择年份'}],
      }
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
  },
  watch: {
    areaCascaderSelected(val) {
      if(val && val.length) {
        this.formDatas.areacode = val[val.length-1];
        this.$refs.modalForm.clearValidate('areacode')
      }
    },
    deptCascaderSelected(val) {
      if(val && val.length) {
        this.formDatas.userdepid = val[val.length-1];
        this.$refs.modalForm.clearValidate('userdepid')
      }
    },
    'formDatas.year'(val) {
      this.formDatas.halfyear = '';
    },
    'formDatas.halfyear'(val) {
      this.formDatas.quarter = '';
    },
    'formDatas.quarter'(val) {
      this.formDatas.month = '';
    },
  },
  created() {
    this.init();
  },
  methods: {
    filter(inputValue, path) {
      return path.some(option => option.userdepname.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    },
    areaFilter(inputValue, path) {
      return path.some(option => option.areaname.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    },
    init() {
      this.initAreaOptions();
      this.getYearOptions();
    },
    // getAreaOptions() {
    //   this.showLoading();
    //   let params = {
    //     parentcode: ''
    //   };
    //   getAreaByLift(params).then(res => {
    //     this.hideLoading();
    //     if(res && res.returncode == '0') {
    //       this.areaOptions = res.item;
    //     }
    //   })
    // },
    initAreaOptions(parentcode='') {
      this.areaOptions = [
        {
          areacode: 'all',
          areaname: '全国',
        }
      ];
      let params = {
        parentcode
      };
      getAreaByLift(params).then(res => {
        if(res && res.returncode == '0') {
          this.areaOptions[0].children = res.item.map(item => {
            item.isLeaf = (item.areacode.length == 6 && item.areacode.substr(-2) != '00');
            return item;
          });
        }
      })
    },
    areaOnLoadChildren(selectedOptions) {
      const targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true;
      this.getChildArea(targetOption.areacode);
    },
    getChildArea(parentcode) {
      let targetOption = getItemFromArrayByKey(this.areaOptions, 'areacode', parentcode);
      let params = {
        parentcode
      }
      getAreaByLift(params).then(res => {
        targetOption.loading = false;
        if(res && res.returncode == '0') {
          targetOption.children = res.item.map(item => {
            item.isLeaf = (item.areacode.length == 6 && item.areacode.substr(-2) != '00');
            return item;
          });
          this.areaOptions = [...this.areaOptions]
        }
      }).catch(err => {
        targetOption.loading = false;
      })
    },
    getYearOptions() {
      this.yearOptions = [];
      let currentYear = moment().year();
      for(let i=4; i>-1; i--) {
        this.yearOptions.push(currentYear-i+'');
      }
    },
    exportData() {
      this.$refs.modalForm.validate(valid => {
        if(valid) {
          let params = {
            areacode: this.formDatas.areacode == 'all' ? '' : this.formDatas.areacode,
          };
          if(this.formDatas.userdepid) {
            params.userdepid = parseInt(this.formDatas.userdepid);
          }
          if(this.formDatas.year) {
            params.year = parseInt(this.formDatas.year);
          }
          if(this.formDatas.halfyear) {
            params.halfyear = parseInt(this.formDatas.halfyear);
          }
          if(this.formDatas.quarter) {
            params.quarter = parseInt(this.formDatas.quarter);
          }
          if(this.formDatas.month) {
            params.month = parseInt(this.formDatas.month);
          }
          this.showLoading();
          if(this.pageType == 'maintenance') {
            exporMaintenanceWordStatistic(params).then(()=>{
              this.hideLoading();
            }).catch(()=>{
              this.hideLoading();
            })
          }else {
            exporIotWordStatistic(params).then(()=>{
              this.hideLoading();
            }).catch(()=>{
              this.hideLoading();
            })
          }
        }else {
          return false;
        }
      })
    }
  }
}
</script>